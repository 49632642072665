<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1 class="main-header" v-translate>Search photos</h1>

    </div>

    <CCard class="no-borders">

      <CCardBody bodyWrapper>
        <CRow class="align-items-center justify-content-between">
          <CCol xl="auto" class="mb-3">
            <CForm @submit.prevent="search" class="flex-grow-1">

              <div class="d-md-flex flex-md-row">
                <div class="d-flex flex-row mb-3 mb-md-0 align-items-center">
                  <CInput
                      v-model="searchText"
                      maxlength="9"
                      required
                      :placeholder="$gettext('Pica Code or BIB number')"
                      class="mb-0 mr-2"
                      style="min-width: 250px">
                  </CInput>
                  <CButton type="submit" color="primary" class="mr-2">
                    <translate>Search</translate>
                  </CButton>
                </div>
                <ul class="list-inline d-flex mb-0 search-info-results align-items-center"
                    v-if="picaCodeData">
                  <li class="list-inline-item"><span class="badge badge-outline badge-primary pica-code">{{
                      textRaceNumber|upper
                    }}</span></li>
                  <li class="list-inline-item">
                    <a @click="copyPicacode(picaCodeData.picacode)" class="badge badge-outline badge-primary pica-code"
                       style="padding-bottom: 3px;cursor: pointer">
                      <CIcon name="cip-code-clean" height="20"/>
                      {{ picaCodeData.picacode }}
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <CIcon
                        v-c-tooltip="{content: $gettext('Checkin performed'), placement: 'top-start', appendToBody: true}"
                        customClasses="text-success ml-1"
                        name="cip-check-circle" width="20"></CIcon>
                  </li>
                </ul>
              </div>
            </CForm>
          </CCol>

          <CCol xl="auto" class="mb-3">
            <ul class="list-inline d-flex mb-0 search-info-results align-items-center"
                v-if="picaCodeData">
              <li class="list-inline-item">
                <span v-if="isPromo"><translate>Photo count</translate>: {{ photosCount }}</span>
                <span v-else><translate
                    :translate-params="{photosCountBought: photosCountBought, photosCount:photosCount}">Bought photos: %{photosCountBought} of %{photosCount}</translate></span>
              </li>
              <li class="list-inline-item" v-if="hasPerm('dashboard.view_feature_download_hd_zip')">
                <CLink v-if="!zipRequesting" href="#" @click.prevent="downloadHdZip(false)"
                       :disabled="photosCountBought === 0">
                  <CIcon :style="{ color: photosCountBought > 0 ? 'inherit' : '#CDCDCD'}"
                         v-c-tooltip="{content: isPromo ? $gettext('Download all checkin photos'): $gettext('Download all bought photos'), placement: 'top-end', appendToBody: true}"
                         class="" name="cip-download-circle" height="34"/>
                </CLink>
                <CSpinner v-if="zipRequesting" color="primary" size="sm" style="width:20px;height:20px;"/>

              </li>
            </ul>
          </CCol>
        </CRow>


        <div v-if="showNoPicaCodeFound || picaCodeData">
          <div v-if="showNoPicaCodeFound" class="text-danger mt-2 font-weight-semi-bold">
            <translate>Pica Code not found</translate>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CAlert v-if="showNoMediasFound" show color="light" class="text-center">
      <h6 class="m-0" v-translate>Photos not found</h6>
    </CAlert>


    <div class="text-center pt-2" v-else-if="loadingMedias">
      <view-loading-message :loading="true"/>
    </div>


    <template v-else>
      <CRow>
        <CCol sm="12" v-if="medias" class="text-right">
          <ul class="list-inline mb-5 list-type-view">
            <li class="list-inline-item">
              <CLink @click.prevent="selectedTypeView=TYPE_VIEW.list"
                     :class="['mr-3', {'on': selectedTypeView===TYPE_VIEW.list}]">
                <CIcon name="cip-lines" width="24"/>
              </CLink>
            </li>
            <li class="list-inline-item">
              <CLink @click.prevent="selectedTypeView=TYPE_VIEW.grid"
                     :class="[{'on': selectedTypeView===TYPE_VIEW.grid}]">
                <CIcon name="cip-grid" width="20"/>
              </CLink>
            </li>
          </ul>
        </CCol>
        <CCol :sm="selectedTypeView === TYPE_VIEW.grid ? 6 : 12" v-for="(media, index) in medias" :key="index"
              class="mb-2">
          <CRow class="mb-1">
            <CCol>
              <ul class="list-inline d-flex mb-0 search-info-results-det justify-content-between align-items-center">
                <li class="list-inline-item">
                  <span v-if="media.shot_at">{{ media.shot_at|formatDateTime('LL HH:mm:ss', timeZone) }}</span>
                  <span v-else>-</span>
                </li>

                <li class="list-inline-item">
                  <span v-if="media.photographer">{{ media.photographer.email }}</span>
                  <span v-else>-</span>
                </li>
              </ul>
              <ul class="list-inline d-flex mb-0 search-info-results-det justify-content-between align-items-center">
                <li class="list-inline-item">
                  <span>ID: {{ media.id }}</span>
                </li>
                <li class="list-inline-item">
                  <span class="ml-1">{{ media.original_width }} x {{ media.original_height }}</span>
                </li>
              </ul>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol>
              <figure class="figure position-relative w-100 order__figure rounded">
                <img class="w-100" :src="media.image_url">
                <CBadge color="success" class="text-uppercase badge-bought" v-if="media.is_bought">
                  <translate>Bought</translate>
                </CBadge>
                <CButton class="btn-add-to-order" size="sm" color="primary"
                         v-if="!media.is_bought && hasPerm('pica_ecommerce.add_external_order') && !isPromo"
                         @click="askToAddMediaToOrder(media)" :disabled="media.loading">
                  <translate>Add to order</translate>
                </CButton>


                <CDropdown
                    class="no-arrow order__figure-actions"
                    v-if="canSeeDropdown(media)"
                    placement="bottom-end">
                  <template #toggler-content>
                    <CHeaderNavLink>
                      <CIcon name="cip-more"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem v-if="hasPerm('pica_ecommerce.change_order')" @click="askRemoveMediaPicacode(media)"
                                 :disabled="media.loading">
                    <translate>Remove</translate>
                  </CDropdownItem>
                  <CDropdownItem
                      v-if="!media.is_bought && hasPerm('pica_ecommerce.add_external_order') && !isPromo"
                      @click="askToAddMediaToOrder(media)" :disabled="media.loading">
                    <translate>Add to order</translate>
                  </CDropdownItem>
                  <CDropdownItem v-if="media.is_bought && hasPerm('pica_ecommerce.change_order') && !isPromo"
                                 @click="askRemoveMediaFromOrder(media)" :disabled="media.loading">
                    <translate>Remove from order</translate>
                  </CDropdownItem>
                  <CDropdownItem v-if="media.media_original_url" @click="downloadHD(media)">
                    <translate>Download HD</translate>
                  </CDropdownItem>
                </CDropdown>
                <template v-else-if="canDownloadHD(media)">
                  <a @click.prevent="downloadHD(media)" class="no-arrow order__figure-actions">
                    <CIcon
                        v-c-tooltip="{content: $gettext('Download HD'), placement: 'top-end', appendToBody: true}"

                        name="cip-download-circle" height="34"
                    />
                  </a>
                </template>


              </figure>
            </CCol>
          </CRow>

        </CCol>
      </CRow>

    </template>
  </div>
</template>

<script>
import PicaServiceService from "@/services/pica_service.service"
import MediaService from "@/services/media.service"
import CheckinService from "@/services/checkin.service"
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {mapGetters} from "vuex";
import Downloader from "@/domain/core/utils/downloader";

const TYPE_VIEW = {
  list: 'list',
  grid: 'grid',
}

export default {
  name: "PicaCodeSearch",
  components: {ViewLoadingMessage},


  data() {
    return {
      searchText: '',
      showNoPicaCodeFound: false,
      showNoMediasFound: false,
      loadingMedias: false,

      picaCodeData: null,
      hasCheckins: false,
      medias: null,

      selectedTypeView: null,

      zipRequesting: false
    }
  },

  computed: {
    ...mapGetters('event',
        ['picaServiceId', 'isPromo', 'timeZone']
    ),
    TYPE_VIEW() {
      return TYPE_VIEW
    },

    textRaceNumber() {
      return this.$gettext('Bib') + ': ' + this.picaCodeData.race_number
    },

    photosCount() {
      return this.medias !== null ? this.medias.length : 0
    },

    photosCountBought() {
      return this.medias !== null ? this.medias.filter(x => x.is_bought).length : 0
    },
    photosBoughtMediaIds() {
      // if promo download all photos otherwise only bought
      if (this.isPromo) {
        return this.medias !== null ? this.medias.map(x => x.id) : null
      } else {
        return this.medias !== null ? this.medias.filter(x => x.is_bought).map(x => x.id) : null
      }
    },
    picacodeQuery() {
      return this.$route.query.picacode
    }
  },

  mounted() {
    this.selectedTypeView = this.TYPE_VIEW.grid

    if (this.picacodeQuery) {
      this.searchText = this.picacodeQuery
      this.search()
    }
  },


  methods: {
    copyPicacode(picacode) {
      navigator.clipboard.writeText(picacode);
      this.notifyMessage({text: this.$gettext('Pica Code copied!'), messageType: 'success'})
    },
    search() {
      this.showNoPicaCodeFound = false
      this.showNoMediasFound = false
      this.loadingMedias = false
      this.medias = null

      let pars = {}
      if (this.searchText.length === 9)
        pars.picacode = this.searchText
      else
        pars.raceNumber = this.searchText

      PicaServiceService.getPicacodeReleased(this.picaServiceId, pars)
          .then((response) => {
            if (response.data.count === 0) {
              this.showNoPicaCodeFound = true
            }

            if (response.data.count === 1) {
              this.picaCodeData = response.data.results[0]
              //this.searchText = ''
              this.hasCheckins = false

              const picaCode = this.picaCodeData.picacode

              CheckinService.getCheckins({picacode: picaCode})
                  .then((response) => {
                    this.hasCheckins = response.data.count > 0
                  })

              this.loadingMedias = true
              MediaService.getMedias({
                pica_service: this.picaServiceId,
                picacode: picaCode,
                media_type: 'photo',
                page_size: 1000,
              })
                  .then((response) => {
                    this.loadingMedias = false
                    if (response.data.count === 0) {
                      this.showNoMediasFound = true
                    }
                    this.medias = response.data.results
                  })
            }
          })
    },
    askRemoveMediaPicacode(media) {
      this.confirm({
        color: 'danger',
        title: this.$pgettext('photo_search', 'Remove photo'),
        message: this.$pgettext('photo_search', 'The selected photo will be deleted from the user\'s album. Do you want to proceed?'),
        cb_confirm: () => {
          this.removeMediaPicacode(media)
        }
      })
    },
    removeMediaPicacode(media) {
      this.$set(media, 'loading', true)
      MediaService.removeMediaPicacode(media.id, this.picaCodeData.picacode)
          .then(() => {
            this.medias = this.medias.filter(i => i.id !== media.id)
            this.notifyMessage({
              text: this.$gettextInterpolate(
                  this.$gettext('Photo ID %{mediaId} has been removed from %{picacode}'),
                  {mediaId: media.id, picacode: this.picaCodeData.picacode}),
              messageType: 'success'
            })
          })
          .catch(error => {
            this.notifyMessage({text: this.$gettext('Cannot remove photo'), messageType: 'warning'})
          }).finally(() => {
        this.$set(media, 'loading', false)
      })
    },
    askToAddMediaToOrder(media) {
      this.confirm({
        title: this.$pgettext('photo_search', 'Add photo to order'),
        message: this.$pgettext('photo_search', 'The selected photo will be visible without watermark in the user\'s album. Do you want to proceed?'),
        cb_confirm: () => {
          this.addMediaToOrder(media)
        }
      })
    },
    addMediaToOrder(media) {
      this.$set(media, 'loading', true)
      MediaService.addMediaToOrder(media.id, this.picaCodeData.picacode)
          .then(() => {
            this.$set(media, 'is_bought', true)
            this.notifyMessage({
              text: this.$gettextInterpolate(
                  this.$gettext('Photo ID %{mediaId} has been added to order'),
                  {mediaId: media.id}),
              messageType: 'success'
            })
          })
          .catch(error => {
            this.notifyMessage({text: this.$gettext('Cannot add photo to order'), messageType: 'warning'})
          }).finally(() => {
        this.$set(media, 'loading', false)
      })
    },
    askRemoveMediaFromOrder(media) {
      this.confirm({
        color: 'danger',
        title: this.$pgettext('photo_search', 'Remove photo from order'),
        message: this.$pgettext('photo_search', 'The selected photo will be removed from the user\'s order. Do you want to proceed?'),
        cb_confirm: () => {
          this.removeMediaFromOrder(media)
        }
      })
    },
    removeMediaFromOrder(media) {
      this.$set(media, 'loading', true)
      MediaService.removeMediaFromOrder(media.id, this.picaCodeData.picacode)
          .then(() => {
            this.$set(media, 'is_bought', false)
            this.notifyMessage({
              text: this.$gettextInterpolate(
                  this.$gettext('Photo ID %{mediaId} has been removed from order'),
                  {mediaId: media.id}),
              messageType: 'success'
            })
          })
          .catch(error => {
            this.notifyMessage({text: this.$gettext('Cannot remove photo from order'), messageType: 'warning'})
          }).finally(() => {
        this.$set(media, 'loading', false)
      })
    },

    downloadHdZip(deferred = false) {
      this.zipRequesting = true
      let payload = {
        deferred: deferred,
        pica_service_id: this.picaServiceId,
      }

      if (!deferred) payload.media_ids = this.photosBoughtMediaIds

      //console.log(payload)

      MediaService.downloadHdZip(payload).then(response => {
        //console.log(response)
        if (deferred) {
          this.notifyMessage({text: this.$gettext('We sent the photos to your mailbox'), messageType: 'success'})
        } else {
          let blob = new Blob([response.data], {type: 'application/zip'})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `download-${this.picaServiceId}-${this.picaCodeData.picacode}.zip`
          document.body.appendChild(link)
          link.click()
        }

      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.zipRequesting = false
      })
    },
    canSeeDropdown(media) {
      return this.isInGroup('pica-managers')
    },
    canDownloadHD(media) {
      if (!media.media_original_url) return false
      return media.is_bought
    },
    downloadHD(media) {
      console.log('download hd', media)
      return Downloader.downloadFromUrl(media.media_original_url)
    }
  }
}
</script>

